import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Carousel, { Modal, ModalGateway } from 'react-images'
import Slider from "react-slick";


const FeatureGrid = ({ gridItems }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalCurrentIndex, setModalCurrentIndex] = useState(0)

  const closeModal = () => setModalIsOpen(false)
  const openModal = (imageIndex) => {
    setModalCurrentIndex(imageIndex)
    setModalIsOpen(true)
  }

  
  const settings = {
    centerMode: true,
    infinite: true,
    arrows: false,
    slidesToShow: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return(
  <div className="slickHolder">
  <Slider {...settings}>
    {gridItems.map((item, i) => {
      
      const imageInfo = {
        alt: item.node.caption,
        image: item.node.original,
        childImagSharp: item.node.localFile.childImageSharp
      }
      return(
              <div className="sliderItem" key={i}>
              <div onClick={()=> openModal(i)}>
              <PreviewCompatibleImage imageInfo={imageInfo} />
              </div>
              </div>

    )})}
  </Slider>
  {ModalGateway && (
        <ModalGateway>
          {modalIsOpen && (
            <Modal
              onClose={closeModal}
              styles={{
                blanket: base => ({
                  ...base,
                  backgroundColor: 'rgba(16,11,0,0.95)',
                  zIndex: 900,
                }),
                dialog: base => ({ ...base, width: '100%' }),
                positioner: base => ({ ...base, zIndex: 901 }),
              }}
            >
              <Carousel
                views={gridItems.map(image => ({
                  source: image.node.localFile.childImageSharp.fluid.src
                }))}
                frameProps={{ autoSize: 'height' }}
                currentIndex={modalCurrentIndex}
                components={{ FooterCount: () => null }}
                styles={{
                  footer: base => ({
                    ...base,
                    background: 'none !important',
                    color: '#666',
                    padding: 0,
                    paddingTop: 20,
                    position: 'static',
                    '& a': { color: 'black' },
                  }),
                  header: base => ({
                    ...base,
                    background: 'none !important',
                    padding: 0,
                    paddingBottom: 10,
                    position: 'static',
                  }),
                  headerClose: base => ({
                    ...base,
                    color: '#666',
                    ':hover': { color: '#DE350B' },
                  }),
                  view: base => ({
                    ...base,
                    overflow: 'hidden',
                    objectFit: 'contain',
                    '& > img': {
                      maxHeight: '75vh',
                      height: 'auto',
                      width: 'auto',
                      margin: '0 auto',
                    },
                  }),
                }}
              />
            </Modal>
          )}
        </ModalGateway>
      )}
  </div>
)
    }

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
  allInstaNode {
    edges {
      node {
        caption
        original
        localFile {
          childImageSharp {
              fluid(
            maxWidth: 800
            quality: 100
            traceSVG: {
              color: "#A38C88"
              turnPolicy: TURNPOLICY_MAJORITY
              blackOnWhite: true
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
            }
        }
      }
    }
  }
}
    `}
    render={(data) => <FeatureGrid gridItems={data.allInstaNode.edges} />}
  />
)

