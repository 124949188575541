import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import stressControl from '../img/stresscontrol.svg'
import insta from '../img/instagram.svg'

import Seo from '../components/Seo'
import Logo from '../components/svg/logo'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Content, {HTMLContent} from '../components/Content'
import Layout from '../components/Layout'
import Features from '../components/Features'
import OtherPosts from '../components/OtherPosts'


export const IndexPageTemplate = ({
    image,
    title,
    importantInfo,
    outro,
    contentComponent,
    content,
    description,
    list
}) => {
    const IndexContent = contentComponent || Content
    const postNode = {
        frontmatter: {
            title: title,
            description: description,
            cover: image.childImageSharp.fluid.src
        }
    }

    const postPath = ""
    return (
        <div>
            <Seo postNode={postNode} postPath={postPath}/>
            <section className="hero is-fullheight" id="home">
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <img src={stressControl} alt="stress-control"/>
                    </div>
                </div>
            </section>
            <div className="mobileSpacer" />
            <section className="hero is-fullheight" id="ashwangada">
            <div className="spacer" />
            <div className="container">
                    <div className="columns">
                        <div className="column is-3">
                            <div className="flex column" >
                            <div className="spacer" />    
                            </div>
                        </div>
                        <div className="column is-6">
                        <div className="spacer" />
                         <div className="spacer" />
                                <IndexContent content={content} className="ashContent"/>
                            </div>
                            <div className="column is-3">
                         <div className="spacer" />
                            </div>
                    </div>
                    </div>
            </section> 
            <div className="spacer" />
            <section className="hero medium" id="articles">
                <div className="section">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="content">
                                <div className="columns is-multiline">
                                    <div className="column is-12">
                                        <div className="spacer"/>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OtherPosts />
                <div className="spacer" />
            </section>
            <section className="hero is-fullheight" id="ashwangada">
            <div className="spacer" />
                    <div className="columns is-multiline">
                        <div className="column is-4 is-offset-1">
                            <div style={{ maxWidth: '400px' }}>
                            <img src={stressControl}  width="250" height="auto" alt="energy" style={{margin: 20}}/>

                            </div>
                        </div>
                        <div className="column is-6 flex flexColumn">

                            {list && list.length
                                ? (
                                    <div
                                        className="desktopTop4em">
                                        <ul className="ashlist">
                                            <Logo
                                                style={{
                                                marginLeft: '-2.5em'
                                            }}/> {list.map(item => (
                                                <li key={item + `tag`}>
                                                    <p>{item}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )
                                : null}
                        </div>
                    </div>
            </section> 
            <div className="mobileSpacer"/>
            <section className="hero is-medium" id="recipes">
                <div style={{ display: 'inline-block'}} >
                <img src={insta}  width="64" height="64" alt="energy" style={{marginLeft: '2vw'}}/>
                </div>
      
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <div className="content">
                            <div className="columns is-multiline">
                                <div className="column is-12">
                                    <div className="spacer"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Features />
            </section>
            <div className="mobileSpacer" />
            <div className="spacer" />
            <section className="hero is-fullheight" id="importantInfo">
                <div className="columns is-multiline">
                <div className="mobileSpacer"/>
                    <div className="column is-6 flex flexColumn is-offset-1">
                        <h3
                            className="has-text-weight-semibold is-size-2 mobileMargin10"
                            style={{
                            alignSelf: 'baseline'
                        }}>
                            WAŻNE INFORMACJE
                        </h3>
                        {importantInfo && importantInfo.length
                            ? (
                                <div
                                    style={{
                                    marginTop: `4rem`
                                }}>
                                    <ul className="importantList">
                                        {importantInfo.map(item => (
                                            <li key={item + `tag`}>
                                                <p>{item}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )
                            : null}
                    </div>
                    <div className="column is-5">
                    <div className="mobileSpacer"/>
                    <div className="mobileSpacer"/>
                        <h3
                            className="has-text-weight-semibold is-size-2 mobileMargin10"
                            style={{
                            alignSelf: 'baseline'
                        }}>
                            KUPISZ MNIE W:
                        </h3>
                        {outro && outro.pharmacy.length
                            ? (
                                <div
                                    style={{
                                    marginTop: `4rem`,
                                    display: 'flex',
                                    marginLeft: '0.3rem',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}>
                                    {outro
                                        .pharmacy
                                        .map(item => (
                                            <a className="pharm" href={item.link} title={item.text} key={item.text}>
                                                <PreviewCompatibleImage
                                                    imageInfo={{
                                                    image: item.image,
                                                    alt: item.text
                                                }}/>
                                            </a>
                                        ))}
                                </div>
                            )
                            : null}
                    </div>
                </div>
            </section>
            <div className="mobileSpacer"/>
            <div className="mobileSpacer"/>
        </div>
    )
}

IndexPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    list: PropTypes.array,
    importantInfo: PropTypes.array,
    description: PropTypes.string,
    intro: PropTypes.shape({recipes: PropTypes.array})
}

const IndexPage = ({data}) => {
    const {frontmatter} = data.markdownRemark

    return (
        <Layout>
            <IndexPageTemplate
                content={data.markdownRemark.html}
                contentComponent={HTMLContent}
                image={frontmatter.image}
                title={frontmatter.title}
                heading={frontmatter.heading}
                list={frontmatter.list}
                importantInfo={frontmatter.importantInfo}
                description={frontmatter.description}
                intro={frontmatter.intro}
                outro={frontmatter.outro}/>
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({frontmatter: PropTypes.object})
    })
}

export default IndexPage

export const pageQuery = graphql `
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        image {
            childImageSharp {
              fluid(
            maxWidth: 1920
            quality: 100
            traceSVG: {
              color: "#A38C88"
              turnPolicy: TURNPOLICY_MAJORITY
              blackOnWhite: true
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
            src
          }
            }
        }
        heading
        subheading
        list
        importantInfo
        description
        intro {
          recipes {
            image {
                childImageSharp {
              fluid(
            maxWidth: 800
            quality: 100
            traceSVG: {
              color: "#A38C88"
              turnPolicy: TURNPOLICY_MAJORITY
              blackOnWhite: true
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
            }
            }
            text
          }
        }
        outro {
          pharmacy {
            image {
                childImageSharp {
              fluid(
            maxWidth: 250
            quality: 100
            traceSVG: {
              color: "#A38C88"
              turnPolicy: TURNPOLICY_MAJORITY
              blackOnWhite: true
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
            }
            }
            text
            link
          }
        }
      }
    }
  }
`
